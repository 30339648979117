import React, { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { PUBLIC, PRIVATE, ERROR, AUTH } from "./routes";
import { useAppSelector } from "../store/hooks";
import Loader from "../components/common/loader";

// const AdminLayout = lazy(() => import("../layouts/adminLayout"));
const EditorLayout = lazy(() => import("../layouts/editorLayout"));
// const ContributorLayout = lazy(() => import("../layouts/contributorLayout"));
const AuthLayout = lazy(() => import("../layouts/authLayout"));
const SuperAdminLayout = lazy(() => import("../layouts/superadminLayout"));

const Login = lazy(() => import("../pages/auth/login"));

const DashboardPage = lazy(() => import("../pages/admin/dashboard"));
const User = lazy(() => import("../pages/admin/users"));
const AddUser = lazy(() => import("../pages/admin/users/create"));
const Profile = lazy(() => import("../pages/admin/profile"));
const UserProfile = lazy(() => import("../pages/admin/users/profile"));

const Posts = lazy(() => import("../pages/admin/posts"));
const AddPost = lazy(() => import("../pages/admin/posts/create"));
const Comment = lazy(() => import("../pages/admin/posts/comments"));
const Sectors = lazy(() => import("../pages/admin/sectors"));
const Topics = lazy(() => import("../pages/admin/topics"));
const Tags = lazy(() => import("../pages/admin/tags"));
const Location = lazy(() => import("../pages/admin/locations"));
const HeadLines = lazy(() => import("../pages/admin/headline"));

const Roles = lazy(() => import("../pages/admin/roles"));
const AddRoles = lazy(() => import("../pages/admin/roles/create"));

const Notification = lazy(() => import("../pages/admin/notifications"));

const Subscriber = lazy(() => import("../pages/admin/subscribedUsers"));
const EditSubscriber = lazy(
  () => import("../pages/admin/subscribedUsers/edit")
);

const ChatRoom = lazy(() => import("../pages/admin/chatroom"));
const ChatRoomMenbers = lazy(
  () => import("../pages/admin/chatroom/chatMembers")
);
const Welcome = lazy(() => import("../pages/public/welcome"));
const AddNewCommunity = lazy(
  () => import("../pages/admin/chatroom/addNewCommunity")
);
const ListSubscriptionData = lazy(() => import("../pages/admin/subscription"));
const AddNewSubscriptionData = lazy(
  () => import("../pages/admin/subscription/create")
);

const Media = lazy(() => import("../pages/admin/media"));

const Orders = lazy(() => import("../pages/admin/orders"));

const MoneyWalletRefCodes = lazy(
  () => import("../pages/admin/moneyWalletRefCodes")
);

const CreateMoneyWalletRefCode = lazy(
  () => import("../pages/admin/moneyWalletRefCodes/create")
);

const MoneyWalletTree = lazy(
  () => import("../pages/admin/moneyWalletRefCodes/moneyTree")
);

const MediaGallery = lazy(
  ()=> import("../pages/admin/mediaGallery")
)

const ResetPassword = lazy(() => import("../pages/auth/reset-password"));

const Error = lazy(() => import("../pages/common/Error"));

const MainRoute: React.FC<any> = () => {
  const { isAppInitialized, user } = useAppSelector((state: any) => state.user);

  if (!isAppInitialized) {
    return <Loader />;
  }

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {/* Auth Routes */}
        <Route path={AUTH.BASE_PATH} element={<AuthLayout />}>
          <Route
            path={AUTH.PAGES.LOGIN}
            element={
              <Suspense fallback={<Loader />}>
                <Login />
              </Suspense>
            }
          />
          <Route
            path={AUTH.PAGES.RESET_PASSWORD}
            element={
              <Suspense fallback={<Loader />}>
                <ResetPassword />
              </Suspense>
            }
          />
          <Route index element={<Navigate to={AUTH.PAGES.LOGIN} />} />
        </Route>

        {/* Super Admin Routes */}
        <Route
          path={PRIVATE.ADMIN.SUPER_ADMIN_BASE_PATH}
          element={<SuperAdminLayout isSuperAdmin />}
        >
          <Route
            path={PRIVATE.ADMIN.PAGES.USER}
            element={
              <Suspense fallback={<Loader />}>
                <User />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.COMMENT_LIST}
            element={
              <Suspense fallback={<Loader />}>
                <Comment />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.ADD_USER}
            element={
              <Suspense fallback={<Loader />}>
                <AddUser />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.USER_PROFILE}
            element={
              <Suspense fallback={<Loader />}>
                <UserProfile />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.ADMIN_PROFILE}
            element={
              <Suspense fallback={<Loader />}>
                <Profile />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.AREA_POSTS}
            element={
              <Suspense fallback={<Loader />}>
                <Posts />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.GENERAL_POSTS}
            element={
              <Suspense fallback={<Loader />}>
                <Posts />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.ADD_POST}
            element={
              <Suspense fallback={<Loader />}>
                <AddPost />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.EDIT_POST}
            element={
              <Suspense fallback={<Loader />}>
                <AddPost />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.ADD_SECTOR}
            element={
              <Suspense fallback={<Loader />}>
                <Sectors />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.ADD_TOPICS}
            element={
              <Suspense fallback={<Loader />}>
                <Topics />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.ADD_TAGS}
            element={
              <Suspense fallback={<Loader />}>
                <Tags />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.ROLES}
            element={
              <Suspense fallback={<Loader />}>
                <Roles />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.ADD_ROLES}
            element={
              <Suspense fallback={<Loader />}>
                <AddRoles />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.EDIT_ROLES}
            element={
              <Suspense fallback={<Loader />}>
                <AddRoles />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.NOTIFICATION}
            element={
              <Suspense fallback={<Loader />}>
                <Notification />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.SUBSCRIBER}
            element={
              <Suspense fallback={<Loader />}>
                <Subscriber />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.SUBSCRIPTION_PAGE}
            element={
              <Suspense fallback={<Loader />}>
                <ListSubscriptionData />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.ADD_NEW_SUBSCRIPTION_DATA}
            element={
              <Suspense fallback={<Loader />}>
                <AddNewSubscriptionData />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.EDIT_SUBSCRIPTION_DATA}
            element={
              <Suspense fallback={<Loader />}>
                <AddNewSubscriptionData />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.EDIT_SUBSCRIBER}
            element={
              <Suspense fallback={<Loader />}>
                <EditSubscriber />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.CHAT_ROOM}
            element={
              <Suspense fallback={<Loader />}>
                <ChatRoom />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.CHAT_ROOM_MEMBERS}
            element={
              <Suspense fallback={<Loader />}>
                <ChatRoomMenbers />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.ADD_NEW_COMMUNITY}
            element={
              <Suspense fallback={<Loader />}>
                <AddNewCommunity />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.DASHBOARD}
            element={
              <Suspense fallback={<Loader />}>
                <DashboardPage />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.LOCATION_MANAGMENT}
            element={
              <Suspense fallback={<Loader />}>
                <Location />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.HEADLINE_MANAGE}
            element={
              <Suspense fallback={<Loader />}>
                <HeadLines />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.MEDIAS}
            element={
              <Suspense fallback={<Loader />}>
                <Media />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.MEDIA_GALLERY}
            element={
              <Suspense fallback={<Loader />}>
                <MediaGallery />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.ORDER}
            element={
              <Suspense fallback={<Loader />}>
                <Orders />
              </Suspense>
            }
          />

          <Route
            path={PRIVATE.ADMIN.PAGES.MONEY_WALLET_REF_CODES}
            element={
              <Suspense fallback={<Loader />}>
                <MoneyWalletRefCodes />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.ADD_MONEY_WALLET_REF_CODE}
            element={
              <Suspense fallback={<Loader />}>
                <CreateMoneyWalletRefCode />
              </Suspense>
            }
          />

          <Route
            path={PRIVATE.ADMIN.PAGES.MONEY_WALLET_TREE}
            element={
              <Suspense fallback={<Loader />}>
                <MoneyWalletTree />
              </Suspense>
            }
          />

          <Route
            path={ERROR.CATCH_ALL}
            element={
              <Suspense fallback={<Loader />}>
                <Error type={404} />
              </Suspense>
            }
          />
          <Route index element={<Navigate to={PRIVATE.ADMIN.PAGES.INDEX} />} />
        </Route>

        {/* Admin Route */}

        {/* Editor Route */}
        <Route
          path={PRIVATE.BASE_PATH.replace(":userType", user?.role)}
          element={<EditorLayout />}
        >
          <Route
            path={PRIVATE.ADMIN.PAGES.USER}
            element={
              <Suspense fallback={<Loader />}>
                <User />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.WELCOME}
            element={
              <Suspense fallback={<Loader />}>
                <Welcome />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.ADD_USER}
            element={
              <Suspense fallback={<Loader />}>
                <AddUser />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.USER_PROFILE}
            element={
              <Suspense fallback={<Loader />}>
                <UserProfile />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.ADMIN_PROFILE}
            element={
              <Suspense fallback={<Loader />}>
                <Profile />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.POSTS}
            element={
              <Suspense fallback={<Loader />}>
                <Posts />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.ADD_POST}
            element={
              <Suspense fallback={<Loader />}>
                <AddPost />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.EDIT_POST}
            element={
              <Suspense fallback={<Loader />}>
                <AddPost />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.ADD_SECTOR}
            element={
              <Suspense fallback={<Loader />}>
                <Sectors />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.ADD_TOPICS}
            element={
              <Suspense fallback={<Loader />}>
                <Topics />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.ADD_TAGS}
            element={
              <Suspense fallback={<Loader />}>
                <Tags />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.LOCATION_MANAGMENT}
            element={
              <Suspense fallback={<Loader />}>
                <Location />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.HEADLINE_MANAGE}
            element={
              <Suspense fallback={<Loader />}>
                <HeadLines />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.ROLES}
            element={
              <Suspense fallback={<Loader />}>
                <Roles />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.ADD_ROLES}
            element={
              <Suspense fallback={<Loader />}>
                <AddRoles />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.EDIT_ROLES}
            element={
              <Suspense fallback={<Loader />}>
                <AddRoles />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.NOTIFICATION}
            element={
              <Suspense fallback={<Loader />}>
                <Notification />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.SUBSCRIBER}
            element={
              <Suspense fallback={<Loader />}>
                <Subscriber />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.SUBSCRIPTION_PAGE}
            element={
              <Suspense fallback={<Loader />}>
                <ListSubscriptionData />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.ADD_NEW_SUBSCRIPTION_DATA}
            element={
              <Suspense fallback={<Loader />}>
                <AddNewSubscriptionData />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.EDIT_SUBSCRIPTION_DATA}
            element={
              <Suspense fallback={<Loader />}>
                <AddNewSubscriptionData />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.EDIT_SUBSCRIBER}
            element={
              <Suspense fallback={<Loader />}>
                <EditSubscriber />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.CHAT_ROOM}
            element={
              <Suspense fallback={<Loader />}>
                <ChatRoom />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.CHAT_ROOM_MEMBERS}
            element={
              <Suspense fallback={<Loader />}>
                <ChatRoomMenbers />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.ADD_NEW_COMMUNITY}
            element={
              <Suspense fallback={<Loader />}>
                <AddNewCommunity />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.MEDIAS}
            element={
              <Suspense fallback={<Loader />}>
                <Media />
              </Suspense>
            }
          />

          <Route
            path={PRIVATE.ADMIN.PAGES.MONEY_WALLET_REF_CODES}
            element={
              <Suspense fallback={<Loader />}>
                <MoneyWalletRefCodes />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.ADD_MONEY_WALLET_REF_CODE}
            element={
              <Suspense fallback={<Loader />}>
                <CreateMoneyWalletRefCode />
              </Suspense>
            }
          />

          <Route
            path={PRIVATE.ADMIN.PAGES.MONEY_WALLET_TREE}
            element={
              <Suspense fallback={<Loader />}>
                <MoneyWalletTree />
              </Suspense>
            }
          />

          <Route
            path={ERROR.CATCH_ALL}
            element={
              <Suspense fallback={<Loader />}>
                <Error type={404} />
              </Suspense>
            }
          />
          <Route
            index
            element={<Navigate to={PRIVATE.ADMIN.PAGES.WELCOME} />}
          />
        </Route>

        {/* Contributor */}

        {/* Other Routes */}
        <Route
          path={ERROR.CATCH_ALL}
          element={
            <Suspense fallback={<Loader />}>
              <Error type={404} />
            </Suspense>
          }
        />
        <Route
          path={ERROR.ERROR_403}
          element={
            <Suspense fallback={<Loader />}>
              <Error type={403} />
            </Suspense>
          }
        />
        <Route index element={<Navigate to={PUBLIC.PAGES.LANDING} />} />
      </Routes>
    </Suspense>
  );
};

export default MainRoute;
