import ROLES from "../config/roles";

export const PUBLIC = {
  BASE_PATH: "/",
  PAGES: {
    LANDING: "auth/login",
  },
};

// AUTH
export const AUTH = {
  BASE_PATH: "/auth",
  PAGES: {
    LOGIN: "login",
    FORGOT_PASSWORD: "forgot-password",
    RESET_PASSWORD: "reset-password",
    STUDENT_REGISTRATION: "student-registration",
  },
};

//PRIVATE
export const PRIVATE = {
  BASE_PATH: "/:userType",
  ADMIN: {
    SUPER_ADMIN_BASE_PATH: ROLES.SUPER_ADMIN,
    ADMIN_BASE_PATH: ROLES.ADMIN,
    EDITOR_BASE_PATH: ROLES.EDITOR,
    CONTRIBUTOR_BASE_PATH: ROLES.CONTRIBUTOR,
    PAGES: {
      INDEX: "dashboard",
      DASHBOARD: "dashboard",
      USER: "user-list",
      ADD_USER: "user-create",
      USER_PROFILE: "user/profile/:id",
      ADMIN_PROFILE: "admin/profile",
      POSTS: "content-list",
      AREA_POSTS: "area-list",
      GENERAL_POSTS: "general-list",
      COMMENT_LIST: "comment-list/:id",
      ADD_POST: "content-create",
      EDIT_POST: "edit-post/:id",
      ADD_SECTOR: "sector-create",
      ADD_TOPICS: "topic-create",
      ADD_TAGS: "tag-create",
      ROLES: "roles",
      ADD_ROLES: "add-role",
      EDIT_ROLES: "edit-role/:name/:label",
      SETTINGS: "settings",
      CHANGE_PASSWORD: "settings/change-password",
      NOTIFICATION: "notification",
      SUBSCRIBER: "subscribers-list",
      SUBSCRIPTION_PAGE: "subscription-create",
      ADD_NEW_SUBSCRIPTION_DATA: "add-subscribtion",
      EDIT_SUBSCRIPTION_DATA: "add-subscribtion/:id",
      EDIT_SUBSCRIBER: "edit-subscriber/:id",
      CHAT_ROOM: "chatgroup-create",
      // CHAT_ROOM: "chat-room",
      CHAT_ROOM_MEMBERS: "chat-room-members/:id",
      ADD_NEW_COMMUNITY: "chat-room",
      // ADD_NEW_COMMUNITY: "chatgroup-create",
      MEDIAS: "media-lists",
      LOCATION_MANAGMENT: "locations",
      HEADLINE_MANAGE: "headline-create",
      WELCOME: "welcome",
      ORDER: "order-list",
      MONEY_WALLET_REF_CODES: "wallet-list",
      ADD_MONEY_WALLET_REF_CODE: "wallet-create",
      MONEY_WALLET_TREE: "wallet-tree/:id",
      MEDIA_GALLERY:"media-gallery"
    },
  },
};

export const ERROR = {
  ERROR_403: "/403",
  CATCH_ALL: "*",
};
